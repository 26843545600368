<template>
    <div id="job-detail">
        <v-head title="本地中醫藥職位招聘"></v-head>
        <div class="content">
            <div class="job-item" v-if="detail">
               <div class="row-title">
                    <span class="job-type" v-text="detail.employmentType"/>
                    <h1 class="title" v-text="detail.title"></h1>
                    <span class="count">({{detail.number}}名)</span>
                </div>
                <div class="job-location">
                    <span class="location-icon" v-if="detail.location"></span>
                    <span class="location-text" v-text="detail.location"></span>
                </div>
                <div :class="{'btn-apply':true, 'applied': applied==true}" v-if="showJoinBtn && applied != undefined" @click="doApply" v-text="applied ? '已申請' : '申請職位'"></div>
                <div class="job-intro">
                    <div class="intro-title">職位有效期</div>
                    <div class="intro-content" v-html="detail.expiredate || '--'"></div>
                </div>
                <div class="job-intro">
                    <div class="intro-title">就業類型</div>
                    <div class="intro-content" v-html="detail.employmentType || '--'"></div>
                </div>
                <div class="job-intro">
                    <div class="intro-title">學歷要求</div>
                    <div class="intro-content" v-html="detail.qualification || '--'"></div>
                </div>
                <div class="job-intro">
                    <div class="intro-title">工作經驗</div>
                    <div class="intro-content" v-html="detail.workyear || '--'"></div>
                </div>
                <div class="job-intro">
                    <div class="intro-title">職位責任</div>
                    <div class="intro-content" v-html="detail.jobdesc || '--'"></div>
                </div>
                <div class="job-intro">
                    <div class="intro-title">任職資格</div>
                    <div class="intro-content" v-html="detail.requirement || '--'"></div>
                </div>
                <div v-if="detail.note" class="job-intro">
                    <div class="intro-title">備註</div>
                    <div class="intro-content" v-html="detail.note"></div>
                </div>
                <div class="job-owner">
                    <div class="company"><img :src="`${detail.cliniclogo || ''}`" :alt="`${detail.company}`" onerror="this.onerror=null; this.src=''" ></div>
                    <div class="job-owner-intro">
                        <div class="company-name" v-text="detail.company"></div>
                        <div class="company-intro" v-html="detail.companydesc"></div>
                        <div class="company-tel">
                            <span class="icon"></span>
                            <a class="txt" :href="`tel://${detail.contact}`" v-text="detail.contact"></a>
                        </div>
                        <div class="company-offtime">
                            <span class="icon"></span>
                            <span class="txt" v-text="`截止日期：${(detail.expiredate || '').substring(0,10)}`"></span>
                        </div>
                        <div class="company-address">
                            <span class="icon"></span>
                            <a class="txt" target="_blank" :href="`https://www.google.com/maps?q=${(detail.address || '').replace(/樓\w+室$/, '樓')}&loc:${detail.lat}+${detail.lng}&hl=zh-hk&z=16&t=p`" v-text="detail.address"></a>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
        <loading v-if="loading" style="padding-top: 200px;"/>
    </div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
    import job_detail from '@/views/mixins/job_detail'
    import Loading from '@/layouts/Loading.vue'
	export default {
        mixins:[
            job_detail,
        ],
        components: {
            vHead,
            Loading,
        },
        data: function(){
            return {
                gpsUrl: '',
                detail: {},
                loading: false,
                applied: false,
                hasResume: true,
                showJoinBtn: false,
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            } 
			this.onRefresh()
            if(this.$store.getters.clientId) {
                const params = {
                    "clientId": this.$store.getters.clientId,
                    "uid": this.$store.getters.clientId,
                }
                this.$store.dispatch('getResume', params).then((resume)=>{
                    this.hasResume = resume && resume.exist;
                });
            }
        },
        methods: {
            doApply() {
                if(!this.$store.getters.clientId) {
                    this._toast.warning(this, '未登录')
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 1500)
                    return
                }
                if (this.applied == true) return
                if(this.hasResume != true) {
                    this._toast.success(this, '請先添加履歷')
                    setTimeout(() => {
                        window.location.href = '/user/resume'
                    }, 500);
                    return;
                }
                this.loading = true
                this.$store.dispatch('delivery', {
                    "clientId": this.$store.getters.clientId,
                    "uid": this.$store.getters.clientId,
                    "jobId": this.detail.jobid,
                }).then((data)=>{
                    this.loading = false
                    if(data == true) {
                        this.applied = true
                        this._toast.success(this, '投遞成功')
                    }
                }).catch((error)=> {
                    this.loading = false
                    this._toast.warning(this, error)
                })
            },
            getEmploymentType: async function() {
                return await this.$store.dispatch('getEmploymentType', {}) || []
            },
            getQualification: async function() {
                return await this.$store.dispatch('getQualification', {}) || []
            },
            getWorkYears: async function() {
                return await this.$store.dispatch('getWorkYears', {}) || []
            },
            openMap: function() {
                this.$store.dispatch('getClinicDetail', {clinicid: this.detail.clinicid})
                .then(detail => {
                    this.detail.lat = detail.lat
                    this.detail.lng = detail.lng
                    this.detail = {...this.detail}
                })
            },
            setAreaName: async function()  {
                // const data = this.detail
                // const params = {
                //     areaLevel1Id: data.arealevel1id,
                //     areaLevel2Id: data.arealevel2id,
                //     langcode: 'B5',
                // }
                // const area = await this.$store.dispatch('getAreaDetail', params)
                // data.location = area.codedesc

                // this.detail = {...this.detail}
                var areaList = await this.$store.dispatch('getAreaList', {'langcode': 'B5'})
                const data = this.detail
                console.log('data', data)
                const params = {
                    areaLevel1Id: data.arealevel1id,
                    areaLevel2Id: data.arealevel2id,
                    langcode: 'B5',
                }
                const area1 = areaList.find((v)=>v.codeid == data.arealevel1id)
                const area2 = await this.$store.dispatch('getAreaDetail', params)
                data.location = `${area1.codedesc} ${area2.codedesc}`
                this.detail = {...this.detail}
            },
            onRefresh: async function(){
                var jobId = this.$route.params.jobId
                if(jobId) {
                    const employmentTypeList = await this.getEmploymentType();
                    const qualificationList = await this.getQualification();
                    const workYearsList = await this.getWorkYears();
                    this.loadData({ 
                        jobId: jobId,
                    }, (data, error)=>{
                        if(error) {
                            this._toast.warning(this, error)
                        } else {
                            this.detail = data
                            this.showJoinBtn = this.detail && this.detail.status == 'A'
                            this.applied = false
                            if(this.detail && this.detail.delivered == true) {
                                this.applied = true
                            }
                            var employmentTypeData = this.detail.employmenttypeid && employmentTypeList && employmentTypeList.find((v)=>v.codeid == this.detail.employmenttypeid)
                            this.detail.employmentType = employmentTypeData && employmentTypeData.codeDesc;
                            var qualificationData = this.detail.qualificationid && qualificationList && qualificationList.find((v)=>v.codeid == this.detail.qualificationid)
                            this.detail.qualification = qualificationData && qualificationData.codeDesc;
                            var workyearData = this.detail.workyearid && workYearsList && workYearsList.find((v)=>v.codeid == this.detail.workyearid)
                            this.detail.workyear = workyearData && `至少${workyearData.codeDesc}年工作經驗`;
                            this.setAreaName()
                            this.openMap()

                            // this.$store.dispatch('queryDeliveries', {
                            //     "clientId": this.$store.getters.clientId,
                            //     "uid": `${this.$store.getters.clientId}`
                            // }).then((rs)=>{
                            //     console.log(rs)

                            // })
                        }
                    })
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    #job-detail {
        width: 100%;
        display: flex;
	    padding-top: 83px;
        flex-direction: column;
    }
    .content {
        flex: 1;
        position: relative;
        margin: 0px auto;
        margin-top: 20px;
    }

    .job-item {
        padding: 32px 26px;
        background-color: #fff;
        border-radius: 4px;
        width: 100vw;
    }

    .job-item > .btn-apply {
        margin: 60px 0px 40px 0px;
        height: 60px;
        line-height: 60px;
        padding: 0 50px;
        box-sizing: border-box;
        display: inline-block;
        text-align: center;
        color: #fff;
        font-size: 28px;
        font-weight: 500;
        background-color: #36C4D0;
        box-shadow: 2px 2px 5px #ccc;
        border-radius: 8px;
        user-select: none;
        cursor: pointer;
    }

    .job-item > .btn-apply:active {
        box-shadow: none;
    }

    .job-item > .btn-apply.applied {
        box-shadow: none;
        background-color: #d6d6d6;
    }

    .job-item > .row-title {
        display: flex;
        flex-direction: row;
        line-height: 25px;
    }

    .job-item > .row-title .job-type {
        display: inline-block;
        line-height: 42px;
        height: 42px;
        padding: 0px 8px;
        border-radius: 2px;
        margin: auto 0px;
        margin-right: 10px;
        font-size: 28px;
        font-weight: bold;
        color: #FFFFFF;
        background-color: #2B99A6;
    }

    .job-item > .row-title .title {
        color: #231F20;
        font-size: 48px;
        font-weight: bold;
        line-height: 48px;
    }

    .job-item > .row-title .count {
        color: #9E9E9E;
        font-size: 48px;
        line-height: 1.0;
        margin-left: 12px;
        margin-right: 35px;
    }

    .job-item .job-location {
        margin-top: 20px;
    }
    
    .job-item .location-icon {
        display: inline-block;
        width: 28px;
        height: 32px;
        margin-top: 10px;
        margin-right: 12px;
        background-image: url(../../../assets/imgs/pc/jobs/icon_dingwei@2x.png);
        background-size: 28px 32px;
        vertical-align: bottom;
    }
    .job-item .location-text {
        color: #2B99A6;
        font-size: 32px;
        line-height: 32px;
        margin-top: 10px;
    }

    .job-item .job-intro {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
    }

    .job-item .job-intro .intro-title {
        color: #231F20;
        font-size: 28px;
        line-height: 28px;
    }

    .job-item .job-intro .intro-content {
        color: #969696;
        font-size: 28px;
        margin-top: 28px;
    }

    .job-item .job-intro:first-child {
        margin-bottom: 35px;
    }

    .job-item .job-owner {
        display: flex;
        flex-direction: row;
        margin-top: 48px;
        padding-top: 12px;
        border-top: 2px dotted #eee;
    }

    .job-item .job-owner > .company {
        display: inline-block;
        width: 68px;
        height: 68px;
        object-fit: contain;
        border: 1px solid #eee;
        border-radius: 4px;
        margin-right: 25px;
    }
    .job-item .job-owner > .company img {
        display: inline-block;
        width: 68px;
        height: 68px;
        object-fit: contain;
        padding: 2px;
        box-sizing: border-box;
    }
    .job-item .job-owner > .company > img[src=""] {
        display:none;
    }

    .job-item .job-owner > .job-owner-intro {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .job-item .job-owner > .job-owner-intro .company-name {
        font-size: 48px;
        line-height: 48px;
        font-weight: bold;
        color: #231F20;
    }

    .job-item .job-owner > .job-owner-intro .company-intro {
        font-size: 28px;
        color: #969696;
        line-height: 40px;
        margin-top: 28px;
        padding-right: 48px;
    }

    .job-item .job-owner > .job-owner-intro .company-tel,
    .job-item .job-owner > .job-owner-intro .company-offtime,
    .job-item .job-owner > .job-owner-intro .company-address
     {
        line-height: 60px;
        position: relative;
        padding-left: 44px;
        color: #231F20;
        font-size: 32px;
    }

    .job-item .job-owner > .job-owner-intro .company-tel {
        margin-top: 60px;
    }
    .job-item .job-owner > .job-owner-intro .company-tel > .icon {
        position: absolute;
        top: 16px;
        left: 0px;
        width: 28px;
        height: 28px;
        background-image: url(../../../assets/imgs/pc/jobs/img_iphone@2x.png);
        background-size: 28px 28px;
    }

     .job-item .job-owner > .job-owner-intro .company-offtime > .icon {
        position: absolute;
        top: 16px;
        left: 0px;
        width: 28px;
        height: 28px;
        background-image: url(../../../assets/imgs/pc/jobs/img_time@2x.png);
        background-size: 28px 28px;
    }

     .job-item .job-owner > .job-owner-intro .company-address > .icon {
        position: absolute;
        top: 16px;
        left: 0px;
        width: 22px;
        height: 28px;
        background-image: url(../../../assets/imgs/pc/jobs/img_dib@2x.png);
        background-size: 22px 28px;
    }

</style>
